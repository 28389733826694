<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="ordersHistory"
            :fields="fields"
            @row-clicked="clicked"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(create_user)="data">{{
          data.item.create_user ? data.item.create_user.login : ""
        }}</template>
        <template #cell(to_addresses)="data">
          <span v-for="(to_address, idx) in data.item.to_addresses" :key="idx">
            {{
              to_address
                ? idx === data.item.to_addresses.length - 1
                  ? to_address.name
                  : to_address.name + "; "
                : ""
            }}
          </span>
        </template>
        <template #cell(performer_name)="data">
          {{
            data.item.performer == null
              ? ""
              : data.item.performer.last_name +
                " " +
                data.item.performer.first_name
          }}
        </template>
        <template #cell(performer_phone)="data">
          {{ data.item.performer ? data.item.performer.phone : "" }}
        </template>
        <template #cell(performer_login)="data">
          {{ data.item.performer ? data.item.performer.login : "" }}
        </template>
        <template #cell(model)="data">
          {{ data.item.model + " (" + data.item.model_editor_login + ")" }}
        </template>
        <template #cell(performer_transport)="data">
          {{
            data.item.performer == null || data.item.performer.transport == null
              ? ""
              : data.item.performer.transport.model +
                `(${data.item.performer.transport.car_number})`
          }}
        </template>
        <template #cell(distance)="data">
          {{
            data.item.distance != null
              ? data.item.distance.toFixed(1) + "км"
              : ""
          }}
        </template>
        <template #cell(allowances)="data">
          <span v-for="(all, index) in data.item.allowances">
            {{
              index === data.item.allowances.length - 1
                ? all.allowance
                : all.allowance + ", "
            }}
          </span>
        </template>

           </b-table>
           </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from '@/utils/TableFunctions/cellSelect'
export default {
  data() {
    return {
        fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "60px" } },
        {
          key: "order_id",
          label: "Id заказа",
          sortable: true,
          thStyle: { width: "80px" },
        },
        {
          key: "created_at",
          label: "Время изменения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "create_user",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "client_phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "price",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "from_address.name",
          label: "Откуда",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "to_addresses",
          label: "Куда",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_phone",
          label: "Номер водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "division",
          label: "Подразделения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "tariff",
          label: "Тариф",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "allowances",
          label: "Надбавки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Время поступления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "distance",
          label: "Растояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_time",
          label: "На время",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      id: this.$route.query.driver_id,
      ordersHistory: []
    };
  },  
  mounted() {
    resizeable();
  },
  methods: {
    clicked(item, index, event){
      cellSelect()
        },
    getHistoryOrders() {
        this.$http
            .get(`drivers/${this.id}/history-orders`)
            .then((res) => {
              this.ordersHistory = res.data.data
            })
            .catch((err) => {
            });
    }
  },
  mounted() {
    this.getHistoryOrders()
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
 